export default {
  methods: {
    /**
     * Extracts a file from an http result and returns it wrapped in a Blob
     * with associated file metadata
     *
     * @param result
     * @param filename
     * @returns {{data: {filename: string, size: string, type: string, category: string}, blob: Blob}}
     */
    async extractFile(result, filename) {
      const name = this.getFileName(result, filename);
      const fileType = this.getFileType(name);

      let contentType = this.getContentType(result);
      if (fileType === 'pdf') contentType = 'application/pdf';

      // Need to await this promise finishing before continuing
      const blob = await result.blob().then((r) => {
        // return a new Blob with the type set
        return new Blob([r], { type: contentType });
      });

      const size = `${Math.ceil(blob.size / 1024)} KB`;

      const output = {
        data: {
          category: fileType,
          filename: name,
          size,
          type: contentType
        },
        blob
      };

      if (fileType === 'text' || fileType === 'code') {
        const reader = new FileReader();

        reader.onload = function onload() {
          output.data.text = reader.result;
        };

        reader.readAsText(blob);
      }

      return output;
    },

    /**
     * Get content type mime string.
     *
     * @param result
     * @returns {string}
     */
    getContentType(result) {
      let type = 'application/octet-stream';
      if (!result.headers) return type;

      const contentTypeHeader = result.headers.get('Content-Type') || result.headers.get('content-type') || result.headers.get('CONTENT-TYPE');
      if (typeof contentTypeHeader !== 'string') return type;

      if (contentTypeHeader) {
        const end = contentTypeHeader.search(';');

        if (end > -1) {
          type = contentTypeHeader.substring(0, end);
        } else {
          type = contentTypeHeader;
        }
      }

      return type;
    },

    /**
     * Grabs the Content-Disposition filename if possible,
     * or defaults to fallback if not or 'export' if no fallback.
     *
     * @param result - http result with Content-Disposition header
     * @param {string} [fallback='export'] - name to use if headers not available
     * @returns {*|string}
     */
    getFileName(result, fallback) {
      let filename = fallback || 'export';

      if (result === null) return filename;
      if (!result.headers) return filename;
      if (result && result.headers['Content-Disposition']) {
        const content = result.headers['Content-Disposition'];
        const nameStart = content.search('filename=');

        // Pull filename out of header
        if (nameStart > -1) {
          filename = content.substring(nameStart + 9);
        }

        // Strip quotes if necessary
        if (filename.indexOf('"') > -1) {
          filename = filename.replace(/"/g, '');
        }
      }

      return filename;
    },

    /**
     * returns common filetype based on filename extension
     *
     * Possible: image, video, archive, document, spreadsheet, audio, text, code, pdf, file
     *
     * @param filename
     * @returns string
     */
    getFileType(filename) {
      if (/\.(GIF|JPG|JPEG|TIFF|PNG|SVG)/i.test(filename)) {
        return 'image';
      }
      if (/\.(3G2|3GP|ASF|ASX|AVI|FLV|M4V|MOV|MP4|MPG|RM|SRT|SWF|VOB|WMV|WEBM)/i.test(filename)) {
        return 'video';
      }
      if (/\.(7Z|CBR|DEB|GZ|AVI|PKG|RAR|RPM|SITX|ZIP|ZIPX)/i.test(filename)) {
        return 'archive';
      }
      if (/\.(TXT|TEXT|LOG|XLOG|RT|ENG)/i.test(filename)) {
        return 'text';
      }
      if (/\.(DOC|DOCX|LOG|MSG|ODT|PAGES|RTF|TEX|WPD|WPS|PPT|PPTX|CWK|DOT)/i.test(filename)) {
        return 'document';
      }
      if (/\.(CSV)/i.test(filename)) {
        return 'csv';
      }
      if (/\.(ODS|XLS|XLSX)/i.test(filename)) {
        return 'spreadsheet';
      }
      if (/\.(AIF|IFF|M3U|M4A|MID|MP3|MPA|RA|WAV|WMA)/i.test(filename)) {
        return 'audio';
      }
      if (/\.(HTML|HTM|HTML5|CSS|SAS|MD|MKD|JS|PROTO|S.7)/i.test(filename)) {
        return 'code';
      }
      if (/\.(PDF)/i.test(filename)) {
        return 'pdf';
      }

      return 'file';
    },

    /**
     * Saves a blob by using the link creation hack
     * TODO - move CommentaryAttachment and Faxback/Postgame to this method
     */
    saveFile(blob, filename) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename || 'CourtVision Download';

      // Click handler that releases the object URL after the element has been clicked
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };

      a.addEventListener('click', clickHandler, false);
      a.click();
    }
  }
};
