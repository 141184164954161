/**
 * Number filter
 * Returns given integer/float in display format
 * rounded to given decimal places
 *
 * @param {integer/float} number
 * @returns {string} String
 *
 * Example: {{ measurements.bodyFat | number(2) }}
 */

import Vue from 'vue';
import { format } from 'd3';
const d3 = { format };

export default function numberFunction(number, decimalPlaces, showSign) {
  if (!_.isNumber(number)) return number;
  if (!_.isInteger(decimalPlaces)) decimalPlaces = 2;

  const formatted = d3.format(`${showSign ? '+' : '-'},.${decimalPlaces}f`)(number);

  return formatted;
}

Vue.filter('number', numberFunction);
