/**
 * DecimalPlaces filter
 * Returns given a number rounded to the given decimal places
 * e.g. 9.8987 -> 9.8
 *
 * @param {number} number
 * @param {integer} decimalPlace
 * @returns {number} Rounded number
 */

import Vue from 'vue';
import { format } from 'd3';
const d3 = { format };

export default function decimalPlacesFunction(input, places) {
  if (!_.isFinite(input)) return input;

  if (places === 0) places = 0; // don't overwrite 0, it is valid
  else if (!places || !_.isInteger(places)) places = 1;

  return d3.format(`,.${places}f`)(input);
}

Vue.filter('decimalPlaces', decimalPlacesFunction);
