/**
 * Commentary Title filter
 * Returns a appropriate title for the commentary type
 * e.g. intel -> intel.subject
 *
 * @param {object} commentary
 * @returns {string} Title
 */

import Vue from 'vue';

export default function commentaryTitleFunction(input) {
  if (!input) return '';

  switch (_.toLower(input.commentaryType)) {
    case 'blotter':
    case 'trade':
    case 'discussion':
    case 'negotiation':
    case 'meeting_notes':
    case 'journal':
    case 'media':
      return input.subject;
    case 'intel':
      if (input.subject) return input.subject;
      if (input.intelSubjects.length) return _.map(input.intelSubjects, 'description').join(', ');
      return 'General Thoughts';
    case 'attachment':
      return input.filename;
    case 'report':
    case 'scouting':
      if (input.reportTitle) {
        return input.reportTitle;
      }

      if (input.eventType) {
        return `${input.eventType} - ${input.location} (${input.medium})`;
      }

      return `${input.location} (${input.medium})`;
    case 'athletic':
      return `Administered by: ${input.admin}`;
    case 'injury':
      return `${input.location} - ${input.injury}`;
    default:
      return '';
  }
}

Vue.filter('commentaryTitle', commentaryTitleFunction);
