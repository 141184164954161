/**
 * Elapsed Time filter
 * Returns the amount of time that has passed since the input
 *
 * @param {date} input (eg. '2019-07-01' or 1561957200000 [millis])
 * @returns {string} Date String ('x years ago')
 */

import Vue from 'vue';
import dayjs from 'dayjs';

export default function elapsedTimeFunction(input) {
  if (!input) return '';

  let output = dayjs(input);
  if (!output.isValid()) return input;
  return output.fromNow();
}

Vue.filter('elapsedTime', elapsedTimeFunction);
