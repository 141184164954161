export default {
  /* Variables */

  // Color scales
  // NOTE - make sure to commit any changes in colors.css as well
  colors: {
    fiesta: ['#ef426f', '#00b2a9', '#ff8200'], // fuschia, teal, orange
    standardTeam: ['#000', '#bbc4ca', '#fff'], // Black, Silver, White

    // Standard scales
    redGreen5Color: ['#ca0020', '#f4a582', '#ffffbf', '#a6d96a', '#1a9641'],
    greenRed5Color: ['#1a9641', '#a6d96a', '#ffffbf', '#f4a582', '#ca0020'],
    redYellowGreen3Color: ['#ca0020', '#ffffbf', '#1a9641'],
    redGrayGreen3Color: ['#ca0020', '#333', '#1a9641'],
    greenGrayRed3Color: ['#1a9641', '#333', '#ca0020'],
    grayYellowRed4Color: ['#efefef', '#ffffbf', '#fee08b', '#ca0020'],
    scouting: ['#78b26f', '#dbac4a', '#c26f6f'], // green, yellow, red
    redBlue8Color: ['#d73027', '#f46d43', '#fdae61', '#fee090', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4'],

    // Text for standard scales
    redGreen5ColorText: ['var(--light)', 'var(--dark)', 'var(--dark)', 'var(--dark)', 'var(--light)'],
    redBlue8ColorText: ['var(--light)', 'var(--dark)', 'var(--dark)', 'var(--dark)', 'var(--dark)', 'var(--dark)', 'var(--dark)', 'var(--light)'],

    // Monochrome Frequency (based on css theme)
    // TODO - some vizes use custom grayscale, move to this one?
    frequencySequential: ['var(--el-24)', 'var(--el-16)', 'var(--el-12)', 'var(--el-8)', 'var(--el-4)', 'var(--el-1)'],
    frequencySequentialText: ['var(--el-0)', 'var(--el-0)', 'var(--el-0)', 'var(--el-0)', 'var(--el-24)', 'var(--el-24)'],

    // Load Report specific scales
    loadReportColorBreaks: [-149, -99, -49, -24, 26, 51, 101, 151],
    loadReportColors: ['#ca0020', '#f4a582', '#ffffbf', '#a6d96a', '#1a9641', '#a6d96a', '#ffffbf', '#f4a582', '#ca0020'],
    loadReportColorsText: ['var(--light)', 'var(--dark)', 'var(--dark)', 'var(--dark)', 'var(--light)', 'var(--dark)', 'var(--dark)', 'var(--dark)', 'var(--light)'],
    rehabStatusColors: ['#ca0020', '#f4a582', '#fee08b', '#d9ef8b', '#a6d96a', '#1a9641'],

    // Same colors as in salary-book.css - change in both places
    salaryBook: {
      capHold: '#ffed6f',
      capHoldText: 'var(--dark)',
      earlyTerminationOption: '#80b1d3',
      earlyTerminationOptionText: 'var(--dark)',
      negative: '#e31a1c',
      nonGuaranteed: '#e31a1c',
      playerOption: '#b3de69',
      playerOptionText: 'var(--dark)',
      teamOption: '#fdb462',
      teamOptionText: 'var(--dark)'
    }
  },

  // Availability
  availability: {
    availabilityArray: ['Unavailable', 'Doubtful', 'Questionable', 'Probable', 'Needs Review', 'Available (with Caution)', 'Available'],
    availabilityAbbrArray: ['U', 'D', 'Q', 'P', 'NR', 'AC', 'A'],
    availabilityColorIndex: [0, 1, 2, 2, 2, 3, 4],
    rehabStatus: ['Initial', 'Intermediate Functional', 'Intermediate Specific', 'Integration to Training', 'Integration to Competition', 'Return to Performance']
  },

  // PPS breaks
  efficiencies: {
    playTypes: {
      drives: [83.0, 93.9, 103.2, 114.3],
      handoff: [69.6, 86.7, 100.0, 120.0],
      isolation: [69.6, 86.7, 100.0, 119.2],
      offball: [72.7, 90.5, 105.3, 122.2],
      picks: [81.0, 91.9, 100.0, 112.1],
      postup: [72.7, 91.7, 108.3, 125.0]
    },
    ppsBreaks: [0.95, 1.05, 1.15, 1.25]
  },

  // Routing Defaults
  defaults: {
    playerId: '93C6C881-B77C-4586-B1E1-9753E11C71E0', // Victor Wembanyama
    teamId: '9D34AFA4-5194-4317-977F-B1D8FAF9D1B8', // San Antonio Spurs
    spursId: '9D34AFA4-5194-4317-977F-B1D8FAF9D1B8', // San Antonio Spurs
    gleagueTeamId: '009902E3-49B5-4AF8-A5D4-3B451CA3D5E4', // Austin Spurs
    austinId: '009902E3-49B5-4AF8-A5D4-3B451CA3D5E4', // Austin Spurs

    agentId: '0D0CC62B-17A8-4979-B273-20C77683EF0D', // Arn Tellem -- Wasserman Media Group
    coachId: '206C539C-694A-42CF-B390-3A7BB9DEFA9F', // Gregg Popovich -- COACH
    contactId: '421DDEFD-BEA4-4F33-8C36-08EAC25F40B8', // Larry Greer -- CONTACT
    scoutId: '467F07FD-6ECF-4093-AE18-9CF79855B459' // Dave Telep  -- SPURS SCOUT
  },

  // Old Positions
  positions: {
    position1: 'Guard',
    position2: 'Wing',
    position3: 'Shooting Big',
    position4: 'Big',
    position5: 'Mobile Big'
  },

  // Positions and Designations
  positionsList: ['1', '1-2', '2-1', '2', '2-3', '3-2', '3', '3-4', '4-3', '4', '4-5', '5-4', '5'],
  positionsSimpleList: ['1', '2', '3', '4', '5'],
  designationsList: ['Guard', 'Wing', 'Big'],

  // Scouting Report Fields
  scouting: {
    amCategories: [
      { label: 'Focus List - Draft', property: 'isFocusListDraft' },
      { label: '2-Way', property: 'isTwoWayDraft' },
      { label: 'Stash Candidate', property: 'isStashCandidate' },
      { label: '61st Pick', property: 'is61stPick' },
      { label: 'Austin Spurs', property: 'isAustinCandidate' },
      { label: 'Draft Workout', property: 'isWorkoutGuy' },
      { label: 'PIT', property: 'isPit' }
    ],
    draftLabels: ['1-5', '5-10', '10-20', '20-30', '25-35', '30-40', '40-50', '50-60', '50-Undrafted', 'Undrafted'],
    eventTypes: [
      { label: 'Game', property: 'Game' },
      { label: 'Tournament', property: 'Tournament' },
      { label: 'Practice', property: 'Practice' },
      { label: 'General Thoughts', property: 'General Thoughts' },
      { label: 'Video Session', property: 'Video Session' },
      { label: 'Camp', property: 'Camp' },
      { label: 'Workout', property: 'Workout' },
      { label: 'Summer League', property: 'Summer League' },
      { label: 'Preseason Phase', property: 'Preseason Phase' },
      { label: 'Phase 1', property: 'Phase 1' },
      { label: 'Phase 2', property: 'Phase 2' },
      { label: 'Phase 3', property: 'Phase 3' },
      { label: 'All-Star Event', property: 'All-Star Event' },
      { label: 'FIBA Competition', property: 'FIBA Competition' },
      { label: 'HS Game', property: 'HS Game' },
      { label: 'Travel Team Game', property: 'Travel Team Game' },
      { label: 'USAB Trial', property: 'USAB Trial' }
    ],
    grassrootsCategories: [
      { label: 'One and Done', property: 'isLikelyOneAndDone' },
      { label: '2-3 Years', property: 'isLikelyTwoOrThreeYear' },
      { label: '4 Years', property: 'isLikelyFourYear' }
    ],
    proCategories: [
      { label: 'Focus List - Trade', property: 'isFocusListTrade' },
      { label: 'Focus List - Free Agency', property: 'isFocusListFreeAgency' },
      { label: '2-Way', property: 'isTwoWayPro' },
      { label: 'Mini Camp', property: 'isMiniCampInvite' },
      { label: 'Summer League', property: 'isSummerLeagueCandidate' },
      { label: 'Austin Spurs', property: 'isAustinCandidate' }
    ]
  },

  // Intel Report Fields
  intel: {
    categories: [
      { label: 'High School', property: 'High School' },
      { label: 'College', property: 'College' },
      { label: 'Draft', property: 'Draft' },
      { label: 'Pro', property: 'Pro' },
      { label: 'Other', property: 'Other' }
    ],
    confidenceLevels: [
      { label: '1', property: 1 },
      { label: '2', property: 2 },
      { label: '3', property: 3 },
      { label: '4', property: 4 }
    ],
    subjects: [
      { label: 'Background', property: 'Background' },
      { label: 'Character', property: 'Character' },
      { label: 'Contract', property: 'Contract' },
      { label: 'Family', property: 'Family' },
      { label: 'Health', property: 'Health' },
      { label: 'Incident', property: 'Incident' },
      { label: 'Injury Recovery', property: 'Injury Recovery' },
      { label: 'Interview', property: 'Interview' },
      { label: 'Life Style', property: 'Life Style' },
      { label: 'Personality', property: 'Personality' },
      { label: 'Playing Ability', property: 'Playing Ability' },
      { label: 'Playing Situation', property: 'Playing Situation' },
      { label: 'Representation', property: 'Representation' },
      { label: 'Work Ethic', property: 'Work Ethic' }
    ]
  },

  // Discussion mediums
  discussionMediums: [
    { label: 'Analytical', property: 'Analytical' },
    { label: 'Code', property: 'Code' },
    { label: 'Conference Call', property: 'Conference Call' },
    { label: 'Email', property: 'Email' },
    { label: 'Email Newsletter', property: 'Email Newsletter' },
    { label: 'ESPN.Com', property: 'ESPN.Com' },
    { label: 'Film', property: 'Film' },
    { label: 'General Information', property: 'General Information' },
    { label: 'Internet', property: 'Internet' },
    { label: 'Journal', property: 'Journal' },
    { label: 'Lithuania', property: 'Lithuania' },
    { label: 'Magazine', property: 'Magazine' },
    { label: 'Media', property: 'Media' },
    { label: 'Multiple', property: 'Multiple' },
    { label: 'N/A', property: 'N/A' },
    { label: 'Newspaper', property: 'Newspaper' },
    { label: 'Notes', property: 'Notes' },
    { label: 'Numbers/Statistics', property: 'Numbers/Statistics' },
    { label: 'On-Site', property: 'On-Site' },
    { label: 'Personal', property: 'Personal' },
    { label: 'Phone', property: 'Phone' },
    { label: 'Press Release', property: 'Press Release' },
    { label: 'Radio', property: 'Radio' },
    { label: 'School Reporter', property: 'School Reporter' },
    { label: 'Text Message', property: 'Text Message' },
    { label: 'TV', property: 'TV' },
    { label: 'Unknown', property: 'Unknown' },
    { label: 'Website', property: 'Website' }
  ],

  // Journal mediums
  journalMediums: [
    { label: 'Film', property: 'Film' },
    { label: 'Internet', property: 'Internet' },
    { label: 'Newspaper', property: 'Newspaper' },
    { label: 'On-Site', property: 'On-Site' },
    { label: 'TV', property: 'TV' },
    { label: 'Unknown', property: 'Unknown' },
    { label: 'Website', property: 'Website' }
  ],

  // Media mediums
  mediaMediums: [
    { label: 'Email', property: 'Email' },
    { label: 'Email Newsletter', property: 'Email Newsletter' },
    { label: 'Journal', property: 'Journal' },
    { label: 'Magazine', property: 'Magazine' },
    { label: 'Newspaper', property: 'Newspaper' },
    { label: 'Notes', property: 'Notes' },
    { label: 'Press Release', property: 'Press Release' },
    { label: 'Radio', property: 'Radio' },
    { label: 'School Reporter', property: 'School Reporter' },
    { label: 'Unknown', property: 'Unknown' },
    { label: 'Webpage', property: 'Webpage' },
    { label: 'Website', property: 'Website' }
  ],

  // Latest season of data
  latestSeason: {
    aroles: 2024,
    pv: 2024
  },

  // Locs levels
  locs: {
    analytic: ['', 'Not a Spur', 'Minor League', 'Roster - D', 'Roster - V', 'Rotation', 'SKR', 'Top Starter', 'Core', 'Franchise'], // 1-9
    analyticAbbr: ['', 'NS', 'ML', 'ROS-D', 'ROS-V', 'ROT', 'SKR', 'TS', 'C', 'F'], // 1-9
    draft: ['', 'Not Good Enough', 'Minor League', 'Roster', 'Rotation', 'SKR', 'Top Starter', 'Core', 'Franchise'], // 1-8
    draftAbbr: ['', 'NGE', 'ML', 'ROS', 'ROT', 'SKR', 'TS', 'C', 'FR'], // 1-8
    scouting: ['', 'Not a Spur', 'Minor League', 'Roster - D', 'Roster - V', 'Rotation', 'SKR', 'Top Starter', 'Core', 'Franchise'], // 1-9
    scoutingAbbr: ['', 'NS', 'ML', 'ROS-D', 'ROS-V', 'ROT', 'SKR', 'TS', 'C', 'F'], // 1-9
    scoutingReports: ['Not a Spur', 'Minor League', 'Roster', 'Rotation', 'SKR', 'Top Starter', 'Core', 'Franchise'], // 0-7
    scoutingReportsAbbr: ['NS', 'ML', 'ROS', 'ROT', 'SKR', 'TS', 'C', 'F'] // 0-7
  },

  // Roles and aRoles
  // (in display order)
  roles: [
    { property: 'roleShooter', label: 'Shooter', variable: 'shooter' },
    { property: 'roleScorer', label: 'Scorer', variable: 'scorer' },
    { property: 'roleFinisher', label: 'Finisher', variable: 'finisher' },
    { property: 'roleCreator', label: 'Creator', variable: 'creator' },
    { property: 'roleRebounder', label: 'Rebounder', variable: 'rebounder' },
    { property: 'roleDefender', label: 'Defender', variable: 'defender' },
    { property: 'roleShotBlocker', label: 'Shot Blocker', variable: 'shotBlocker' }
  ],
  trends: [
    { property: 'roleShooterTrend', label: 'Shooter', variable: 'shooterTrend' },
    { property: 'roleScorerTrend', label: 'Scorer', variable: 'scorerTrend' },
    { property: 'roleFinisherTrend', label: 'Finisher', variable: 'finisherTrend' },
    { property: 'roleCreatorTrend', label: 'Creator', variable: 'creatorTrend' },
    { property: 'roleRebounderTrend', label: 'Rebounder', variable: 'rebounderTrend' },
    { property: 'roleDefenderTrend', label: 'Defender', variable: 'defenderTrend' },
    { property: 'roleShotBlockerTrend', label: 'Shot Blocker', variable: 'shotBlockerTrend' }
  ],
  aRoles: ['Shooter', 'Scorer', 'Finisher', 'Creator', 'Rebounder', 'Defender', 'RimProtector'],

  // Types of things
  types: {
    main: [
      { label: 'Players', short: 'Player', type: 'player' },
      { label: 'Teams', short: 'Team', type: 'team' },
      { label: 'Personnel', short: 'Personnel', type: 'personnel' }
    ],

    personnel: [
      { label: 'All Personnel', short: 'Personnel', type: 'personnel' },
      { label: 'Coaches', short: 'Coach', type: 'coach' },
      { label: 'Scouts', short: 'Scout', type: 'scout' },
      { label: 'Agents', short: 'Agent', type: 'agent' },
      { label: 'Contacts', short: 'Contact', type: 'contact' }
    ],

    player: [
      { label: 'All Players', short: 'Players', type: 'player' },
      { label: 'NBA', short: 'NBA', type: 'player', levels: [1] },
      { label: 'International', short: 'INT', type: 'player', levels: [3] },
      { label: 'NCAA Div 1', short: 'DIV 1', type: 'player', levels: [4] },
      { label: 'All College', short: 'College', type: 'player', levels: [4, 5, 6, 7] },
      { label: 'Pre-College', short: 'Pre-College', type: 'player', levels: [8, 9] },
      { label: 'GLeague', short: 'GLeague', type: 'player', levels: [12] }
    ],

    team: [
      { label: 'All Teams', short: 'Teams', type: 'team' },
      { label: 'NBA', short: 'NBA', type: 'team', levels: [1] },
      { label: 'International', short: 'INT', type: 'team', levels: [3] },
      { label: 'NCAA Div 1', short: 'DIV 1', type: 'team', levels: [4] },
      { label: 'All College', short: 'College', type: 'team', levels: [4, 5, 6, 7] },
      { label: 'Pre-College', short: 'Pre-College', type: 'team', levels: [8, 9] },
      { label: 'GLeague', short: 'GLeague', type: 'team', levels: [12] }
    ],

    college: [
      { label: 'Division 1', short: 'DIV 1', type: 'team', levels: [4] },
      { label: 'Division 2', short: 'DIV 2', type: 'team', levels: [5] },
      { label: 'Division 3', short: 'DIV 3', type: 'team', levels: [6] },
      { label: 'NAIA Schools', short: 'NAIA', type: 'team', levels: [7] },
      { label: 'Junior College', short: 'JUCO', type: 'team', levels: [8] }
    ],

    coach: [
      { label: 'All', short: 'All', type: 'coach' },
      { label: 'NBA', short: 'NBA', type: 'coach', levels: [1] },
      { label: 'International', short: 'INT', type: 'coach', levels: [3] },
      { label: 'College', short: 'College', type: 'coach', levels: [4, 5, 6, 7] },
      { label: 'Pre-College', short: 'Pre-College', type: 'coach', levels: [8, 9] },
      { label: 'GLeague', short: 'GLeague', type: 'coach', levels: [12] }
    ],

    clubLeague: ['USPRO', 'USMINOR', 'COLL', 'INTPRO'],
    nonClubLeague: ['HSAAU', 'INTTOURN', 'NATIONAL', 'SUMMER', 'DRAFTCAMP', 'COLLCAMP', 'HSCAMP']
  },

  // Splits
  splitOrder: {
    gameGroups: ['DEFAULT', 'PRE', 'REG', 'REGPOST', 'POST'],
    posGroups: ['A', 'G', 'W', 'B', '1', '2', '3', '4', '5'],
    shotFilterSets: ['All', 'Half Court', 'Transition', 'Uncontested', 'Contested', 'Off Dribble', 'Catch & Shoot', 'Early Clock', 'Mid Clock', 'Late Clock', 'Potential Assist', 'Non Potential Assist'],
    // eslint-disable-next-line max-len
    splits: ['All', 'Wins', 'Losses', 'Home', 'Away', 'Neutral Site', 'Starter', 'Bench', 'Conference', 'Non-Conference', 'Top Competition', 'Non Top Competition', 'Last 5 Player Games', 'Last 10 Player Games', 'Non Last 5 Player Games', 'Non Last 10 Player Games', 'Last 5 Team Games', 'Last 10 Team Games', 'Non Last 5 Team Games', 'Non Last 10 Team Games', 'Before All-Star Break', 'After All-Star Break', 'Pre-Covid', 'Post-Covid', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', '2022-23 15 Gamer #1', '2022-23 15 Gamer #2', 'NBA First Half', 'NBA Second Half', 'NBA Second Half (No OT)', 'NBA OT', '1st Period', '2nd Period', '3rd Period', '4th Period', '5th Period', '6th Period', '7th Period', '8th Period', '9th Period', '10th-99th Periods']
  },

  // Hard-coded since the infrastructure to grab these from the DB is overkill and this shouldn't change
  dbValues: {
    allShotFilterSetId: '12B5E5FE-FB8C-40E4-B7CE-566DBDA4AD2E',
    allSplitId: 'CAD4D4BA-4AAF-4B2A-9967-89D8531FAED7', 
    last10SplitId: '2F35CD5A-2279-4908-A8D2-78BB08A1F7BA',
    last5SplitId: 'EA2D9DFF-4708-4714-8610-5D17DC7BFF08'
  },

  // Zones
  singleZones: ['DEEPPAINT', 'BASKETAREA', 'RBASELINE', 'RWING2', 'MIDSLOT', 'LWING2', 'LBASELINE', 'RCORNER', 'RWING3', 'TOPARC', 'LWING3', 'LCORNER', 'HEAVE'],

  /* Functions */
  getLocsLabel(locs, type) {
    if (!type) type = 'scouting';

    const locsLabel = this.locs[type][locs];
    return locsLabel || '--';
  },

  getScoutingColor(value) {
    return this.colors.scouting[value];
  }
};
